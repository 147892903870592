.btn {
  width: 100%;
  margin-top: 12px;
}
.loginWith {
  margin-top: 20px;
}
.forgotPassword {
  display: block;
  width: fit-content;
  color: var(--wh_1);
  text-decoration: none;
  margin-top: 20px;
}
.backendMessage {
  color: var(--red_2);
  margin-top: 10px;
  text-transform: uppercase;
}
.addCommentBox{ 
  display: flex;
  justify-content: space-between;
  grid-gap: 16px;
  background: var(--gr_19);
  border: 1px solid var(--wh_5);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  
  input {
    padding: 5px 10px;
    outline: none;
    border: none;
    background: none;
    width: 100%;
  }
  button {
    font-weight: bolder;
    border-radius: 4px;
  }
}

.profilePhoto {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.root {
  display: grid;
  grid-template-columns: 100px 346px;
  width: 498px;
  margin: auto;
  margin-bottom: 8px;
  align-items: center;
}
.title {
  font-size: 14px;
  color: var(--wh_15);
}
.select {
  height: 36px;
  outline: none;
  border: 1px solid var(--wh_13);
  border-radius: 4px;
  padding: 0 10px;
}
.root {
  margin-top: 20px;
  padding: 20px 36px 0;
  background-color: var(--bl_4);
  height: 44.5%;
}
.root * {
  color: var(--wh_1);
}
.header {
  display: flex;
}
.button {
  text-align: center;
  width: 100%;
  height: 37px;
  border-bottom: 1px solid var(--gr_14);
}
.buttonActive {
  border-color: var(--wh_1);
}

.content {
  height: 305px;
}

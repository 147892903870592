/* .root {} */
.searchBox {
  position: relative;
}
.searchingText {
  margin-top: 24px;
  background: none;
  outline: none;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid var(--gr_3);
  color: var(--wh_1);
  background-color: var(--bl_2);
}

.searchedResult {
  width: 100%;
  min-height: 40px;
  max-height: 175px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% - 15px);
  left: 0;

  background-color: var(--bl_2);
  border: 1px solid var(--gr_3);
  border-top: none;
  border-radius: 0 0 4px 4px;
}
.searchedUser {
  padding: 12px;
  width: 100%;
  cursor: pointer;
}
.searchedUser:nth-child(odd) {
  background: var(--gr_20);
}
.friends {
  display: flex;
  gap: 16px;
  margin-bottom: 10px;
}
.profilePhoto {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}

.checkboxContainer {
  display: flex;
  gap: 6px;
}
.checkboxContainer > * {
  cursor: pointer;
}
.link {
  text-decoration: none;
}
.disabledLink {
  opacity: .5;
  cursor: initial;
}

.section {
  cursor: pointer;
  display: flex;
  grid-gap: 10px;
  color: var(--wh_1);
  width: 100%;
  height: 48px;
  padding: 12px;
  border-radius: 4px;
  align-items: center;
}
.section:hover {
  background-color: var(--gr_20);
}
.minSection {
  width: initial;
}

.activeSection {
  background-color: var(--gr_21);
}
.activeSection:hover {
  background-color: var(--gr_21);
}

.svg {
  fill: var(--wh_1);
}

.disabledLink:hover {
  background: none;
  cursor: initial;
}
.futures {
  background: var(--g2);
  color: var(--wh_1);
  padding: 74px 0;
}
.itemsBox {
  display: grid;
  grid-gap: 8px;
}

@media (319px <= width < 638px) {
  .itemsBox {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (638px <= width < 957px) {
  .itemsBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (957px <= width < 1312px) {
  .itemsBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (1312px < width) {
  .itemsBox {
    grid-template-columns: repeat(4, 1fr);
  }
}

.headerText {
  /* font-size: 28px; */
  margin-bottom: 20px;
}


.imagesBox {
  position: relative;
}
.banner {
  width: 100%;
  height: 164px;
  object-fit: cover;
  border-radius: 4px;
}
.profilePicture {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  left: 25px;
  bottom: -20%;
  border: 4px solid var(--wh_1);
}
.badges {
  position: absolute;
  margin-left: 8.7rem;
  bottom: -15px;
  display: flex;
  width: 80%;
  overflow-y: hidden;
  overflow-x: auto;
  grid-gap: 4px;
}
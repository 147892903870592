.square {
  --showSize: 10px;
  width: var(--squareSize);
  height: var(--squareSize);
  position: relative;
}
.blackSquare {
  background: var(--bs);
}
.whiteSquare {
  background: var(--ws);
}
.root {
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid var(--wh_1);
  transition: .25;
  display: grid;
  grid-template-columns: 50px auto;
  grid-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--wh_5);
}
.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.textLine_1 {
  margin: 5px 0 10px;
  height: 16px;
  aspect-ratio: initial;
  width: 200px;
}
.img {
  margin-top: 10px;
  aspect-ratio: 16/9;
  height: 20px;
  width: 150px;
}
.header {
  background: var(--g1);
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 94px;
}
.navigation {
  display: grid;
  grid-template-columns: repeat(8, auto);
  align-items: center;
  grid-gap: 28px;
}
.chessLogo {
  color: white;
}

.button {
  margin-left: 49px;
}
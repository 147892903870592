.root {
  --firstCollumnSize: 68px;
  --gap: 6px;

  height: 100dvh;
  width: 456px;
  background-color: var(--bl_1);
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 15px;
}

.root {
  width: 100%;
  padding: 7px;
}
.tab {
  display: flex;
}
.tabButton {
  height: 34px;
  padding: 8px 20px;
  color: var(--wh_15);
}
.tabButtonActive {
  color: initial;
  box-shadow: inset 0 -1px 0px var(--wh_15);
}
.users {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;
  margin-top: 10px;
}
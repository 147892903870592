.root {}
.root * {
  color: var(--wh_1);
}
.gameTypes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  --gap: 12px;
  gap: var(--gap);
}
.gameTypeName {
  margin-top: 20px;
  margin-bottom: 10px;
}
.gameTypeButtons {
  flex-direction: column;
  display: flex;
  gap: var(--gap);
}
div > button.gameDelay {
  border: none;
  width: 128px;
  height: 48px;
  font-size: 18px;
  border-radius: 5px;
  background: var(--gr_13);
}
div > button.gameDelaySelected {
  border: 1px solid var(--gr_5);
}
.colorChoose {
  margin-top: 20px;
  margin-bottom: 10px;
}
.colorChooseText {
  margin-bottom: 10px;
}
.colorsButtons {
  display: flex;
  gap: 5px;
}
.color {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 6px;

  svg {
    width: 46px;
    height: 46px;
  }
}
.colorSelected {
  border: 1px solid var(--gr_5);
}
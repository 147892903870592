.questionBox {
  border-bottom: 1px solid;
  max-width: 1040px;
  margin: auto;
}
.questionBox:last-of-type{
  border-bottom: 0;
}
.questionBlock {
  cursor: pointer;
  height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question {
  width: fit-content;
}
.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.answer {
  margin-top: 8px;
  color: var(--wh_16);
  padding-bottom: 20px;
  line-height: 28px;
}

.leftSideBar {
  padding: 33px 24px;
  width: 310px;
  color: var(--wh_1);
  fill: var(--wh_1);
}

.minLeftSideBar {
  width: inherit;
}

.devTeam{
  margin-top: 2rem;
  opacity: .6;

  h3 {
    margin-bottom: 6px;
  }
}
.dev {
  margin-bottom: 2px;

  a {
    text-decoration: none;
    color: var(--wh_1);
  }
}
.root {
  height: 271px;
}

.root * {
  min-height: 10px;
  border-radius: 4px;
}

.rootPadding {
  padding: 12px;
}

.header {
  display: grid;
  grid-template-columns: 60px 142px 22px;
  grid-gap: 8px;
}
/* .flag {} */
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.nameSurname {

  margin-bottom: .5rem;
}
.username {

  width: 70%;
  margin-bottom: 1.5rem;
}
.starsBox {
  width: 50%;
}

.scoreBox {
  margin: 0 10px;
  margin-bottom: 10px;
}
.scoreBox:nth-child(2) {
  margin-top: 10px;
  width: 60%;
}

.scoreBox:nth-child(3) {
  margin-top: 10px;
  width: 45%;
}

.scoreBox:nth-child(4) {
  margin-top: 10px;
  width: 35%;
}

.footer {
  margin: 0 10px;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
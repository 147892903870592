.root {
  fill: var(--wh_1);
  padding: 5px;
  border-radius: 50%;
  display: flex;
  height: fit-content;
  width: fit-content;
  align-items: center;
  justify-content: center;
}
.noor {
  background-color: var(--red_5);
}
.queen {
  background-color: var(--or_1);
}
.king {
  background-color: var(--bl_5);
}
.rook {
  background-color: var(--grn_3);
}
.bishop {
  background-color: var(--red_6);
}
.pawn {
  background-color: var(--or_2);
}
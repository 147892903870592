.userData {}

/* .usernameBox {} */

.nameSurnameFlag {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.rating {
  display: flex;
  grid-gap: 18px;
}
.ratingType {
  color: var(--wh_15);

  span {
    color: initial;
    font-weight: bold;
  }
}

.username {
  color: var(--pc_3);
}

.counters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px 0px;
}

.counter {
  span {
    font-weight: bold;
  }
}

.profileDescription {
  color: var(--wh_15);
  text-align: justify;
  margin-bottom: 12px;
  max-width: 75%;
}
.likes {
  display: flex;
  grid-gap: 2px;
}
.heartIconBox {
  position: relative;
}
.liked {
  circle {
    stroke: var(--pc_3);
  }
  path {
    fill: var(--pc_3);
  }
}

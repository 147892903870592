.auth {
  display: grid;
  grid-template-columns: 1fr 2.3fr;
  height: 100dvh;
}
.img {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60%;
  overflow: hidden;
}
.img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* next line not worked */
  /* background: radial-gradient(left at 55% 100%, rgba(0, 0, 0, 0), black); */
}
.outlet {
  background: var(--g1);
  color: var(--wh_1);
}
.container {
  width: 406px;
}
.closeButton {
  --padding: 62px;
  fill: var(--wh_1);
  position: absolute;
  right: var(--padding);
  top: var(--padding);
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}
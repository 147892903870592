.root {
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid var(--wh_5);
  transition: .25;
  display: grid;
  grid-template-columns: 50px auto;
  grid-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  transition: .1s;
}

.root:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--wh_5);
}

.profilePicture {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%  ;
}

.content {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.authorInfo {
  display: flex;
  grid-gap: 12px;
}

.authorName {
  text-decoration: none;
  color: black;
}

.publishDate {
  color: var(--wh_15);
  font-size: 14px;
}

.seeMore {
  fill: var(--gr_5);
}

.postText {
  color: var(--wh_15)
}

.statistics {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.statisticButton {
  display: flex;
  grid-gap: 4px;
  align-items: center;
  fill: var(--wh_15);
}
.statisticButton:hover {
  fill: var(--pc_3);
}
.statisticButton:hover .statisticCount {
  color: var(--pc_3);
}
.statisticPressed {
  fill: var(--red_2);
}
.statisticPressed .statisticCount {
  color: var(--red_2);
}
.statisticCount {
  color: var(--wh_15);
  font-size: 14px;
}
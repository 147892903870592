* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
button {
  border: 0;
  outline: 0;
  background: none;
}
.root {
  height: 100%;
  width: 100%;
  background: var(--g3);
  color: white;
  display: flex;
  grid-gap: 88px;
  padding-top: 24px;
  padding-left: 106px;
}

/* === for buttons box === */

.buttons {
  margin-top: 32px;
  .buttonsCotainer {
    display: grid;
    grid-template-columns: repeat(2, 148px);
    grid-template-rows: repeat(3, 94px);
    grid-gap: 12px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--gr_11);
    color: var(--wh_1);
  }
  button:hover {
    background-color: var(--pc_h);
    border-color: var(--pc_h);
  }
  button[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }
  .buttonFirst {
    background-color: var(--pc_h);
    border: 1px solid var(--pc_h);
  }
}

.buttonsHeaderText {
  font-size: 20px;
  margin-bottom: 24px;
}

/* === view games box  */

.viewGamesBox {
  margin-top: 20px;
  width: 200px;
}
.viewGamesHeaderText {
  margin-bottom: 5px;
}

.viewGames {
  max-height: 60dvh;
  overflow: auto;
  padding: 5px;
}

.game {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--wh_10);
  padding: 10px;
  color: var(--wh_1);
  overflow: auto;
}

.viewGameButton {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
}

.disabledButton {
  pointer-events: none;
  opacity: .5;
}
.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}
/* .grid_3 {} */


.img {
  width: 100%;
  border-radius: 5px;
}
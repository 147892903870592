.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6px;
}
.button {
  padding: 10px 20px;
  border: 1px solid var(--wh_1);
  border-radius: 4px;

  fill: var(--wh_1);

  &[disabled] {
    opacity: .4;
    pointer-events: none;
  }
}
.btn {
  width: 100%;
  margin-top: 32px;
}
.cancelBtn {
  cursor: pointer;
  background: none;
  width: 100%;
  margin-top: 32px;
  border: none;
  outline: none;
  color: var(--wh_1);
}
.inputBox {
  margin-bottom: 8px;
} 
.input {
  width: 406px;
  height: 56px;
  padding: 16px;
  background: none;
  border-radius: 4px;
  border: 1px solid var(--wh_10);
  color: var(--wh_1);
  outline: none;
}
/* .input:disabled {} */
.input::placeholder {
  transition: .4s;
}
.input:focus {
  border-color: var(--wh_1);
}
.input:focus::placeholder {
  padding-left: 12px;
  color: var(--wh_1);
}
.inputError {
  border-color: var(--red_2);
}
/* .inputError:focus {
  border-color: var(--red_2);
} */
.errorMessage {
  height: 16px;
  margin-top: 6px;
  padding-left: 16px;
  font-size: 12px;
  color: var(--red_2);
}
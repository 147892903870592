.root {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.circleBox {
  cursor: pointer;
  margin-right: 10px;
  height: 15px;
  width: 34px;
  background-color: var(--gr_1);
  border-radius: 50px;
  transition: .4s;
}
.circle {
  border-radius: 50%;
  background-color: var(--wh_3 );
  height: inherit;
  aspect-ratio: 1/1;
  scale: 1.5;
  position: relative;
  left: 0;
  transition: .4s;
}

.toggled {
  box-shadow: inset 0 0 0px 2px var(--pc);
  background-color: var(--wh_1);

  .circle {
    left: calc(100% - 15px);
    background: var(--pc);
  }
}

.label {
  color: var(--wh_11);
}
.root {
  display: flex;
}
.img {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.actions {
  padding-left: 13px;
  width: 100%;
}
.input {
  padding-top: 10px;
  width: 100%;
  height: 92px;
  resize: none;
  overflow-y: scroll;
  border: none;
  outline: none;
}
.buttons {
  height: 36px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.postButton {
  width: 93px;
  height: 36px;
  padding: 10px 32px;
  background: var(--wh_2);
  cursor: not-allowed;
}
.postButton:hover {
  background: var(--wh_2);
}
.postActiveButton {
  cursor: pointer;
  background: var(--pc_h);
}
.postActiveButton:hover {
  background: var(--red_5);
}
.smallIcons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  align-items: center;
}
.smallIcon {
  width: 20px;
  height: 20px;
  fill: var(--wh_12);
  cursor: pointer;
}
.smallIcon_video {
  transform: translateY(1px);
  height: 40px;
}

.selectedPhotosBox {
  display: flex;
  overflow-x: auto;
  grid-gap: 10px;
  max-width: 70%;
}
.selectedImgBox {
  position: relative;
}
.selectedImage {
  height: 50px;
  border-radius: 4px;
}
.removeButtonBox {
  opacity: 0;
  transition: .3s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedImgBox:hover .removeButtonBox{
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .7);
  backdrop-filter: blur(5px);
  opacity: 1;
}
.button {
  outline: none;
  border: none;
  padding: 15px 22px;
  border-radius: 4px;
  color: var(--wh_1);
  background-color: var(--pc);
  cursor: pointer;
  transition: .3s;
}

.button:hover {
  background-color: var(--red_1);
}

.button:disabled {
  cursor:initial;
  background-color: var(--wh_9);
}
.root {
  width: 100%;
  margin-top: 10px;
}

.comments {
  max-height: 40dvh;
  overflow: auto;
  margin-bottom: 5px;
  min-height: 10dvh;
}
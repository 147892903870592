.download {
  padding: 74px 0;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.phoneMokup {
  width: 500px;
  z-index: 10;
}
.phoneGamplay {
  width: 140px;
  position: relative;
  right: 170px;
  top: 20px;
}
.headerText {
  color: var(--gr_7);
  margin-bottom: 32px;
  span {
    color: var(--pc);
  }
}

.title {
  font-weight: 500;
  margin-bottom: 12px;
}
.description {
  width: 500px;
  margin-bottom: 24px;
  color: var(--wh_15);
  line-height: 22px;
}
.root {
  width: 100%;
  border-radius: 4px;
  padding: 12px 6px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 44px 200px 8px;
  grid-gap: 1rem;
  align-items: center;

  .profileImg {
    width: 100%;
    border-radius: 50%;
    aspect-ratio:  1/1;
    object-fit: cover;
  }
  .message {
    font-size: 14px;
    color: var(--wh_15);
  
    span {
      color: initial;
      font-weight: bold;
    }
  }
  
  .date {
    font-size: 14px;
  }
  
  .notificationStatus {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: var(--wh_2);
  }
}

.isViewed {
  background-color: var(--red_7);

  .notificationStatus {
    background: var(--pc_h);
  }
}
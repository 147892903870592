.board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: fit-content;
  overflow: hidden;
}

.chessBoardBox {
  padding: 10px;
  border: 1px solid var(--gr_10);
  width: fit-content;
  border-radius: 4px;
  margin: 12px 0;
}
.chessBoard {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.boardNumbers {
  display: grid;
  grid-template-rows: repeat(8, var(--squareSize));
  p {
    display: flex;
    align-items: center;
  }
}
.boardChars {
  display: grid;
  grid-template-columns: repeat(8, var(--squareSize));
  p {
    text-align: center;
  }
}
.boardChars:nth-child(1) {
  margin-bottom: 10px;
}
.boardChars:nth-child(3) {
  margin-top: 10px;
}

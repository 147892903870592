/* .root {} */
.modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .15);
  backdrop-filter: blur(5px);
}
.modalContainer {
  position: relative;
  padding: 30px;
  border-radius: 4px;
  background: var(--g3);
}

button.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0 !important;
  border: none !important;
  fill: var(--wh_1);
  
  &:hover {
    background: none !important;
  }
}
.root {
  --fontColor: var(--wh_15);
  width: 490px;
  margin: auto;
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 8px;
}

.titleBox {
  width: 80px;
  display: flex;
}
.titleBoxLarge {
  width: 100%;
}
.title {
  width: inherit;
  font-size: 14px;
  color: var(--fontColor);
}

.inputBox {
  width: 346px;
}
.input {
  width: inherit;
  height: 36px;
  outline: none;
  border: 1px solid var(--wh_13);
  border-radius: 4px;
  padding-left: 1rem;
}
.input::placeholder {
  font-weight: 100;
  color: var(--wh_13);
}
.input:disabled {
  border: 1px solid var(--wh_14);
  background: none;
}
.largeInput {
  padding-top: 10px;
  width: 100%;
  resize: none;
  height: 112px;
  outline: none;
  border: 1px solid var(--wh_13);
  border-radius: 4px;
  padding-left: 1rem;
}
.invalidInput {
  border-color: var(--red_4);
}

/* .changeButtonBox {} */
.changeButton {
  padding: 5px 0;
  color: var(--fontColor);
}
.userPage {
  height: 100dvh;
  --headerHeight: 92px;
}
.content {
  margin: auto;
  display: grid;
  grid-template-columns: auto 771px auto;
  height: calc(100dvh - var(--headerHeight));
}
.minContent {
  grid-template-columns: auto 1349px auto;
}
.contentWithoutRightSideBar {
  grid-template-columns: auto 1135px auto;
}

.leftSideBarBox{ 
  display: flex;
  justify-content: flex-end;
  height: inherit;
  background-color: var(--bl_1);
  overflow-y: scroll;
}

.main {
  background-color: var(--wh_1);
  width: 100%;
  margin: auto;
  height: inherit;
  overflow: hidden;
}

.rightSideBarBox {
  height: inherit;
  background: var(--bl_1);
}
.miniRightSideBar {
  background-color: var(--wh_17);
}
.miniRightSideBar_1 {
  background: var(--g3_-90d);
}
.header {
  background: var(--g1);
  color: var(--wh_1);
}
.link {
  color: var(--wh_1);
  text-decoration: none;
}
.headerContainer {
  max-width: 1440px;
  margin: auto;
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.actions {
  display: flex;
  grid-gap: 28px;
  align-items: center;
}
.startPlayButton {
  margin-right: 29px;
  background: var(--pc_h);
}
.startPlayButton:hover {
  background: var(--red_5);
}
.headerSvg {
  cursor: pointer;
  fill: var(--wh_1);
  position: relative;
}
.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--wh_1);
  background-color: var(--pc_h);
}
.notificationCountSmall {
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 18px;
}

.notificationCountLarge {
  border-radius: 10px;
  width: fit-content;
  padding: 0 5px;
}

/* .notificationsContainer {} */

.headerProfileBox {
  cursor: pointer;
  display: flex;
  align-items: center;
  fill: var(--wh_1);
  grid-gap: 8px;
}
.headerProfilePicture {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.root {
  position: relative;
}
.menu {
  position: absolute;
  z-index: 2;
  width: 222px;
  right: 0;
  top: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--wh_1);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.20);
}

.item{
  padding: 12px 8px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  fill: var(--gr_6);
  color: var(--gr_6);
}

.item:hover {
  background-color: var(--wh_6);
}

.title {
  text-align: left;
  width: 100%;
}
.root {
  text-align: center;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-bottom: 32px;
  align-items: center;
  grid-gap: 10px;
}
.line {
  height: 1px;
  background-color: var(--wh_2);
}

.links {
  width: fit-content;
  margin: auto;
  grid-gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 32px;
}
.haveAccount {
  color: var(--wh_11);
  font-size: 14px;

  a {
    color: var(--wh_1);
    text-decoration: none;
  }
}

.loginWith {
  margin-top: 36px;
}

.toggle {
  margin-top: 16px;
}

.btn {
  margin-top: 32px;
  width: 100%;
} 
.footer {
  padding-top: 77px;
  padding-bottom: 8px;
  background: var(--g2);
  color: var(--wh_1);
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 32px;
}
.right {
  text-align: right;
}
/* .content {} */
.box {
  p {
    margin-bottom: 12px;
    font-size: 14px;
  }
}
.box_4 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 73px;
}
.socialMediaIcons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
.iconBox {
  width: 40px;
}
.icon {
  cursor: pointer;
  fill: var(--wh_1);
}
.icon:hover {
  fill: var(--pc_h);
}
.appsLinks {
  margin-top: 25px;
}
.copyright {
  font-size: 14px;
  text-align: center;
  margin-top: 34px;
}
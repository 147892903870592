.button {
  padding: 10px 32px;
  background: var(--pc_h);
  position: relative;
  left: 437px;
}
.button:hover {
  background: var(--red_5);
}
.errorMessage {
  font-size: 14px;
  text-align: right;
  padding-right: 2.5rem;
  color: var(--red_3);
}
body {
  min-height: 100dvh;
}

button {
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1312px;
  margin: auto;
}

.rotate {
  transform: rotate(180deg);
}

.hoveredText:hover {
  cursor: pointer;
  color: var(--pc_h);
}

.authHeaderText {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
}
.authHeaderText2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
}
.authHeaderDescription {
  margin-bottom: 16px;
  font-size: 14px;
}

.skeleton,
.skeleton_2 {
  --gradient: linear-gradient(-70deg, var(--gr_16), var(--gr_17), var(--gr_16));
  background-color: gray;
  background: var(--gradient);
  background-size: 200%;
  background-position: 0% 0%;
  animation: skeletonAnimation infinite 1s linear alternate;
}
.skeleton {
  aspect-ratio: 1/1;
}

/* .skeleton_2 {} */

@keyframes skeletonAnimationWithLight {
  0%{ 
    box-shadow: 0 0 0 gray;
  } 
  100% {
    box-shadow: 0 0 3px 3px gray;
  }
}

@keyframes skeletonAnimation {
  0%{
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
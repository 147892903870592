.likes {
  display: flex;
  grid-gap: 2px;
  position: absolute;
  right: 0px;
  bottom: 30px;
  background-color: var(--gr_18);
  padding: 0 8px;
  border-radius: 4px;
}
.reactionButton {
  padding: 8px 0;
  transition: .3s;
}
.reactionButton:hover {
  /* transition: .5s; */
  translate: 0 -15px;
  scale: 1.4;
}
.root {
  padding: 12px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 40px auto 24px;
  align-items: center;
  grid-gap: 12px;
  border: 1px solid var(--gr_23);
  position: relative;
}
/* .profilePhotoBox {} */
.profielPhoto {
  width: 40px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}

.infoBox {
  height: 100%;
}
.nameSurname {
  margin-bottom: 2px;
}
.username {
  color: var(--pc_3);
}

/* .menuBox {} */

.root:hover .hoveredBox {
  display: block;
}
.hoveredBox {
  display: none;
  align-items: center;
  position: absolute;
  top: 50px;
  right: -10px;
  width: 273px;
  height: 138px;
  padding: 12px;
  border-radius: 4px;
  background: var(--wh_1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 20;
}
.profileData {
  display: grid;
  grid-template-columns: 40px auto;
  grid-gap: 12px;
}
.buttons {
  display: flex;
  grid-gap: 6px;
  width: fit-content;
  margin: auto;
  margin-top: 24px;

  button {
    padding: 10px;
    border-radius: 4px;
  }

  button:nth-child(1) {
    background: var(--pc_h);
    color: var(--wh_1);
  }
  button:nth-child(2) {
    background: var(--wh_1);
    border: 1px solid var(--wh_24);
    color: var(--wh_15);
  }
}
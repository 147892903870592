.root {
  height: 82px;
  background-color: var(--wh_23);
}
.container {
  height: inherit;
  padding: 12px 0;
  display: flex;
  grid-gap: 15px;
  align-items: center;
}
.friend {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.friendProfileImgBox {
  position: relative;
  height: fit-content;
  --size: 42px;
  width: var(--size);
  height: var(--size);
}
.friendProfileImgBox::after {
  content: "";
  --size: 8px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  right: 4px;
  bottom: 1px;
  background-color: var(--grn_2);
  border-radius: 50%;
}

.friendProfileImg {
  --size: 42px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%  ;
  object-fit: cover;
}

.friendUsername {
  font-size: 14px;
}
.title {
  position: absolute;
  top: 96%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--gr_24);
  color: var(--wh_1);
  height: 32px;
  border-radius: 4px;
  display: none;
}
.friend:hover {
  .title {
    display: block;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgb(31, 31, 31);
}

::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--wh_3);
}
.rightSideBar {
  height: inherit;
  width: 364px;
  overflow: hidden;
}

.chessboardImg {
  padding: 0px 32px;
  width: 100%;
  margin-top: 20px;
}

.buttonsBox {
  padding: 0 32px;
  margin-top: 10px;
  display: grid;
  grid-template-areas: 
    'newGame newGame'
    'vsComputer vsFriend';
  flex-direction: column;
  grid-gap: 8px;
}

.newGame {
  grid-area: newGame;
}
.button:nth-child(3) {
  grid-area: vsComputer;
}
.button:nth-child(2) {
  grid-area: vsFriend;
}
.button {
  width: 100%;
  background: none;
  border: 1px solid var(--wh_16);
}
.button:hover {
  background: none;
}
.searchBox {
  position: relative;
}
.input {
  width: 343px;
  height: 44px;
  border-radius: 25px;
  outline: none;
  border: 1px solid var(--gr_3);
  background: none;
  padding-left: 16px;
  color: var(--wh_1);
}
.input:focus {
  background-color: var(--wh_1);
  color: var(--gr_4);
}
.input:focus::placeholder {
  opacity: 0;
}

.svgIcon {
  position: absolute;
  right: 16px;
  top: 10px;
  fill: var(--gr_3);
}

.searchResult {
  width: 100%;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  padding: 16px;
  height: 400px;
  border-radius: 4px;
  z-index: 2;
  overflow-y: auto;
  background: var(--wh_1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, .15);
}

.user {
  display: block;
  width: 100%;
  border: 1px solid var(--wh_23);
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 5px;
  align-items: center;
  color: initial;
  display: grid;
  grid-template-columns: 42px auto;
  grid-gap: 16px;
  text-align: left;
  cursor: pointer;
}
/* .userProfileImgBox {} */
.profileImg {
  width: 42px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.userInfo {
  width: 100%;
}
.nameSurname {
  margin-bottom: 2px;
}
.username {
  color: var(--pc_3);
}
.blackBg {
  position: absolute;
  left: 0;
  top: var(--headerHeight);
  background-color: rgba(0, 0, 0, .2);
  backdrop-filter: blur(5px);
  width: 100%;
  height: calc(100% - var(--headerHeight));
  z-index: 1;
}
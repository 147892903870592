.item {
  background: var(--g2_180d);
  border-radius: 4px;
  padding: 25px;
  color: white;
  display: flex;
  grid-gap: 12px;
}

@media (319px <= width < 638px) {
  .item{
    .verticalLine {
      display: none;
    }
  }
}

@media (638px <= width < 957px) {
  .item:nth-child(2n) {
    .verticalLine {
      display: none;
    }
  }
}

@media (957px <= width < 1312px) {
  .item:nth-child(3n) {
    .verticalLine {
      display: none;
    }
  }
}

@media (1312px < width) {
  .item:nth-child(4n) {
    .verticalLine {
      display: none;
    }
  }
}

.icon {
  width: 30px;
  height: 32px;
  fill: var(--pc_h);
}
.title {
  font-weight: 800;
}
.description {
  font-size: 14px;
  margin-top: 16px;
}

.verticalLine {
  width: 1px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  background-color: var(--gr_2);
  position: relative;
  left: 29px;
}
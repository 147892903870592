.root {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--g1);

  h1 {
    color: white;
    font-size: calc((1vw + 1vh) * 2);
  }
  
  .link {
    font-size: calc((1vw + 1vh));
    text-align: center;
    width: 100%;
    display: inline-block;
    color: white;
    text-decoration: underline;
  }
}

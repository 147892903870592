:root {
  /* PRIMARY COLORS */
  --pc: #881026;
  --pc_h: #d1193a;

  --pc_2: #ea6809;
  --pc_2_h: #C95B0A;
  
  --pc_3: #26b0a8;
  
  /* CHESSBOARD SQUARES COLORS */
  --bs: #040001;
  --ws: #ACACAB;
  --squareSize: 60px;

  /* FIGURES COLORS */
  --bf: #8E7A6B;
  --wf: #FFFFFF;

  /* SHADES OF GREEN */
  --grn_1: #19C62A;
  --grn_2: #28D119;
  --grn_3: #08978E;

  /* SHADES OF RED */
  --red_1: #770e21;
  --red_5: #B4102D;
  --red_2: #f73154;
  --red_3: #c37f7f;
  --red_4: #FF9EB0;
  --red_5: #BF1A38;
  --red_6: #D4494A;
  --red_7: #FBF3F4;

  /* SHADES OF ORANGE */
  --or_1: #CC3F12;
  --or_2: #966E1F;

  /* SHADES OF BLUE */
  --bl_1: #141517;
  --bl_2: #202122;
  --bl_3: #7fa4c3;
  --bl_4: #1F2225;
  --bl_5: #1173CD;
  --bl_6: #40474F;

  /* SHADES OF WHITE */
  --wh_1: white;
  --wh_2: #d9d9d9;
  --wh_3: #ededed;
  --wh_4: #F5FBFD;
  --wh_5: #EFEFEF;
  --wh_6: #F5F5F5;
  --wh_7: #F6F6F6;
  --wh_8: #c1c1c1;
  --wh_9: #909090;
  --wh_10: #888888;
  --wh_11: #979797;
  --wh_12: #aaaaaa;
  --wh_13: #AFAFAF;
  --wh_14: #afafaf80;
  --wh_15: #8a8a8a;
  --wh_16: #808080;
  --wh_17: #F3F4F6;
  --wh_18: #ECEEF0;
  --wh_19: #E7E8ED;
  --wh_20: #D6D9E0;
  --wh_21: #929294;
  --wh_22: #A9A9A9;
  --wh_23: #EEEEEE;
  --wh_24: #C9C9C9;

  /* SHADES OF GRAY */
  --gr_1: #616163;
  --gr_2: #63605e;
  --gr_3: #757575;
  --gr_4: #6A6A6A;
  --gr_5: #6C6969;
  --gr_6: #4D4D4D;
  --gr_7: #40474f;
  --gr_8: #9B9B9B;
  --gr_9: #444A4F;
  --gr_10: #8D8D8D;
  --gr_11: #8E8E8E;
  --gr_12: #ECEDED;
  --gr_13: #272829;
  --gr_14: #A6A6A6;
  --gr_15: #262A2D;
  --gr_16: rgb(191, 191, 191);
  --gr_17: rgb(241, 241, 241);
  --gr_18: #37393B;
  --gr_19: #F9F9F9;
  --gr_20: #303030;
  --gr_21: #343A40;
  --gr_22: #7C7C7C;
  --gr_23: #ECECEC;
  --gr_24: #636567;

  /* SHADES OF BLACK */
  --bl_1: #18191C;
  --bl_2: #1F2224;

  /* GRADIENTS */
  --g1: linear-gradient(90deg, #1d1d1d, #282c2f, var(--gr_7));
  --g2: linear-gradient(90deg, #0a090b, #1b2023);
  --g2_180d: linear-gradient(180deg, #000000, #1c1d1d);
  --g3: linear-gradient(90deg, #0B0B0D, #282C2F);
  --g3_-90d: linear-gradient(-90deg, #0B0B0D, #282C2F);
  --g4: linear-gradient(259.58deg, #282C2F -22.18%, #0B0B0D 91.85%);
}

.root {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: var(--headerHeight);
	width: 100%;
	max-width: 1450px;
	height: calc(100dvh - var(--headerHeight));
	z-index: 2;
	background-color: rgba(0, 0, 0, .2);
	backdrop-filter: blur(5px);
}

.notifications {
	position: absolute;
	right: 20px;
	top: 5px;
	right: 100px;
	width: 344px;
	height: 500px;
	overflow-y: auto;
	background: var(--wh_1);
	border-radius: 4px;
	padding: 12px 16px;
	z-index: 1;
	color: black;
}
.headerText {
	margin-bottom: 1rem;
	font-weight: bold;
}
.showMovesBox {
  width: 100%;
  height: 317px;
  border: 1px solid var(--wh_15);
  border-radius: 4px;
  overflow-y: auto;
  padding: 12px;
}
.lineHeader:first-child {
  display: grid;
  grid-template-columns: var(--firstCollumnSize) repeat(2, auto);
  gap: var(--gap);
  text-align: center;
  margin-bottom: var(--gap);
  padding: 10px 0;
  color: var(--ws);
}
.lineElem {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ws);
  background: var(--gr_13);
  border-radius: 4px;
}
.lineElemSelected {
  color: var(--wh_1);
  background: var(--gr_6);
}

.movesBox {
  display: grid;
  gap: var(--gap);
  grid-template-columns: var(--firstCollumnSize) auto;
}
.movesCountBox {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.moves {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap);
}
.root {
  position: absolute;
  top: var(--headerHeight);
  left: 0;
  width: 100%;
  height: calc(100% - var(--headerHeight));
  background-color: rgba(0, 0, 0, .15);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modalContainer {
  position: relative;
  padding: 30px;
  border-radius: 4px;
  background: var(--g3);
}

button.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0 !important;
  border: none !important;
  fill: var(--wh_1);
  
  &:hover {
    background: none !important;
  }
}
.sendInviteButton {
  color: var(--wh_1);
  width: 100%;
  margin-top: 10px;
  border: 1px solid var(--gr_4);
  padding: 10px;
  border-radius: 4px;
}
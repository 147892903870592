.root {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.img {
  border-radius: 50%;
  width: 28px;
  height: 28px;
}
/* .username {} */
.inviteGame {
  position: absolute;
  bottom: 109px;
  right: 44px;
  width: 425px;
  height: 161px;
  background: rgba(0, 0, 0, .7);
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 16px auto;
  backdrop-filter: blur(5px);
  z-index: 10;
}
.closeButton {
  position: absolute;
  fill: var(--wh_1);
  right: 16px;
  top: 16px;
}
.leftVerticalLine {
  background-color: var(--bl_6);
}
.content {
  margin-top: 34px;
  margin-left: 23px;
}
.profileInfo {
  display: grid;
  grid-template-columns: 40px auto;
  gap: 8px;
}
.opponentImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.textInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.username {
  font-weight: bolder;
  color: var(--pc_3);
}
/* .invitedText {} */
.buttons {
  margin-top: 28px;
  display: flex;
  gap: 8px;
}
.accept,
.reject {
  padding: 10px 20px;
  border-radius: 4px;
}
.accept {
  background-color: var(--pc_h);
  color: var(--wh_1);
}
.reject {
  border: 1px solid var(--ws);
  color: var(--ws);
}
.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, .2);
}
.container {
  width: 604px;
  padding: 16px;
  margin: auto;
  background: var(--wh_1);
  border-radius: 4px;
  margin-top: 107px;
  position: relative;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.svgClose {
  fill: var(--wh_11);
  width: 14px;
  height: 14px;
}

.headerText {
  color: var(--wh_15);
  font-size: 14px;
  margin-bottom: 6px;
}
.bannerContainer {
  position: relative;
}
.bannerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  grid-gap: 6px;
  
  * {
    z-index: 20;
  }
}
.bannerButtons::after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bannerBox {
  position: relative;
  margin-bottom: 15px;
}
.imageButton {
  padding: 5px 10px;
  margin: 1px;
  border: 1px solid black;
  border-radius: 50px;
  width: fit-content;
}
.banner {
  width: 100%;
  height: 161px;
  object-fit: cover;
  border-radius: 4px;
}
.profilePictureBox {
  position: absolute;
  bottom: -45px;
  left: 45px;
  width: 106px;
  height: 106px;
}
.profilePictureBox::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.profilePicture {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 4px solid var(--wh_1);
}
.profilePictureButton {
  z-index: 20;
  --position: calc(50% - (var(--size) / 2));

  top: var(--position);
  left: var(--position);
  position: absolute;
}

.imageButton {
  --size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background: var(--gr_22);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  outline: none;
  border: none;

  path {
    fill: white;
  }
}
.changeImageText {
  margin-left: 165px;
  color: var(--wh_15);
  margin-bottom: 37px;
}
.saveButton {
  padding: 10px 32px;
  background: var(--pc_h);
  position: relative;
  left: 373px;
}
.saveButton:hover {
  background: var(--red_5);
}
.root {
  position: relative;
}
.linksBox {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  background: var(--g1);
  grid-gap: 5px;
  padding: 10px;
  border-radius: 4px;
  right: 0px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.407);
}
.link {
  text-decoration: none;
  color: white;
  padding: 5px;
}
.link:hover {
  background-color: var(--pc);
}
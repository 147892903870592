.welcome {
  height: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.box {}
.box_1 {
  background: var(--g2);
}
.contentBox {
  color: var(--wh_1);
}
.headerText {
  font-size: 40px;
  span {
    color: var(--pc_h);
  }
}
.description {
  font-size: 36px;
  word-spacing: 10px;
}
.longDescription {
  max-width: 350px;
  margin: 1rem 0;  
  line-height: 22px;
}

.box_2 {
  background-position: center center;
  background-size: cover;
}
.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, .5);
  color: var(--wh_1);
  fill: var(--wh_1);
}

.box {
  background: var(--g4);

  width: 442px;
  height: 516px;
  padding: 56px;
  position: relative;
}
.levels {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  margin-bottom: 2rem;
}

.level,
.signUpButton {
  width: 100%;
}
.level {
  height: 51px;
  background-color: var(--gr_13);
}
.level:hover {
  background-color: var(--gr_13);
}

.levelSelected {
  border: 1px solid var(--wh_1);
}
.signUpButton {
  height: 56px;
}

.closeButton {
  position: absolute;
  right: 21px;
  top: 21px;
}

.headerText {
  font-size: 20px;
  margin-bottom: 2rem;
}
.userHome {
  height: inherit;
  overflow-y: scroll;
}
.userHomeWidth {
  max-width: 606px;
	margin: auto;
}
.newPost {
	margin-top: 32px;
  border-bottom: 1px solid var(--wh_2);
  padding-bottom: 16px;
  margin-bottom: 1rem;
}
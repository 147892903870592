.root {
  width: 100%;
}

.modal {
  padding-top: 202px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, .2);
}
.modalContent {
  width: 606px;
  margin: auto;
  padding: 16px;
  background-color: var(--wh_1);
  border-radius: 4px;
  position: relative;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.whiteFigure {
  fill: var(--wf);
}

.blackFigure {
  fill: var(--bf);
}

.checkKing {
  background: radial-gradient( at 50% 15%, var(--red_3) 5%, rgba(255, 255, 255, 0));
}
.box {
  text-align: center;
}

.headerText {
  font-size: 28px;
  margin-bottom: 1rem;
  font-weight: lighter;
  word-spacing: 7px;
  span {
    color: var(--pc);
  }
}

.p {
  max-width: 58%;
  margin: auto;
  margin-bottom: 1rem;
  line-height: 22px; 
}
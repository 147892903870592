.root {
  grid-gap: 10px;
  width: fit-content;
  width: 100%;
  max-width: 80%;
  margin-bottom: 10px;
  margin-left: 40px;
}

.mainContent {
  display: grid;
  grid-template-columns: 40px min(150px) auto;
  margin-bottom: 5px;
}
.profileImg {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}
.content {
  border-radius: 14px;
  padding: 10px;
  background: var(--gr_19);
  display: flex;
  flex-direction: column;
}

.username {
  text-decoration: none;
  color: black;
  font-size: 12px;
  font-weight: bold;
}
.commentText {
  font-size: 14px;
  color: var(--wh_15);
  margin: 4px 0;
}

.likeButton {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  padding: 0 !important;
  
  p {
    color: rgba(0, 0, 0, .5);
  }
}

.isUserLikedToo {
  svg {
    circle {
      stroke: var(--pc_3);
    }
    path {
      fill: var(--pc_3);
    }
  }
}
.miniMenuBox {
  display: flex;
  align-items: center;
}
.root {
  padding: 2rem;
  height: inherit;
  overflow-y: scroll;
}

.buttons {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 6px;
}
.buttonSvg {
  width: 16px;
}

.newPostButton,
.editProfileButton {
  display: flex;
  padding: 10px;
  grid-gap: 8px;
  align-items: center;
  border-radius: 4px;
  height: 32px;
}

.newPostButton {
  background: var(--pc_h);
  color: var(--wh_1);
  fill: var(--wh_1);
}

.editProfileButton {
  border: 1px solid var(--pc_h);
  color: var(--pc_h);
  fill: var(--pc_h);
}

.tabs {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 20px;
}
.tab {
  padding: 14px;
  border-bottom: 2px solid var(--wh_15);
  color: var(--wh_15);
}
.tabActive {
  color: var(--pc_h);
  border-color: var(--pc_h);
}

.userContent {
  margin-top: 5px;
}
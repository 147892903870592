.root {
  background-color: var(--bl_4);
  margin-bottom: 18px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.rootPadding {
  padding: 12px;
}

.header {
  display: grid;
  grid-template-columns: 60px 142px 22px;
  grid-gap: 8px;
}
.link {
  color: inherit;
  text-decoration: inherit;
}

.ratingBox {
  border-bottom: 1px solid var(--wh_1);
}
.rating {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.ratingType {
  span {
    color: var(--pc_3);
    font-weight: bold;
  }
}
 
/* .imgBox {} */
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
/* .profileInfoBox {} */
.nameSurname {
  font-size: 16px;
  margin-bottom: .2rem;
}
.username {
  font-size: 14px;
  margin-bottom: .4rem;
}
.starsBox {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: fit-content;
  grid-gap: 4px;
}

/* .flagBox {} */

.scoreBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gr_6);
}
/* .title {} */
/* .score {} */

.icons {
  display: flex;
  grid-gap: 6px; 
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myProfileLink {
  color: var(--wh_1);
  text-decoration: none;
}
.questions {
  padding: 82px 0;
}

.headerText {
  max-width: 1040px;
  margin: auto;
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--wh_16);
}
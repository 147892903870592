.root {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.item {
  padding: 8px;
  display: grid;
  grid-template-columns: 60px 55px 60px 100px;
  background: var(--gr_15);
  border-bottom: 1px solid var(--gr_6);
}
.item:last-child {
  border-bottom: none;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.username {
  font-size: 14px;
}
.spectateButton {
  width: 72px;
  height: 32px;
  border: 1px solid var(--wh_1);
  border-radius: 4px;
}

.liveBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.verticalLine {
  width: 1px;
  height: 26px;
  background: var(--pc_h);
}

.cyrcle {
  height: 7px;
  width: 7px;
  position: relative;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  border-radius: 50%;
  background: var(--pc_h);
}

.cyrcle::after {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  left: calc((15px / 2 - 3px) * -1);
  top: calc((15px / 2 - 4px) * -1);
  background-color: var(--pc_h);
  opacity: 0.5;
}
